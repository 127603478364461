<template>
	<div class="left_menu">
		<div class="head_wrap">
			<div class="head_box" v-if="userinfo.pid==0">
				<el-upload
					class="avatar-uploader"
					:action="uploadAction"
					:show-file-list="false"
					:on-success="handleAvatarSuccess">
					<img class="vip_tag" src="../../assets/images/vip_tag.png" />
					<img class="head" :src="headImg">
					<p class="icons"><i class="iconfont icon-camera"></i></p>
				</el-upload>
			</div>
			<div class="head_box" v-else>
				<img class="vip_tag" src="../../assets/images/vip_tag.png" />
				<img class="head" :src="headImg">
			</div>
			<p class="txt">Hi，{{userinfo.nickname || userinfo.name}}</p>
			<!-- <p class="viptxt">2022.10.27到期<a href="/vip">去续费</a></p> -->
		</div>
		<ul class="links_list">
			<li v-for="(item,index) in ucmenuList" :class="item.menu_id==curId?'active':''">
				<a @click="$router.push(item.url+'?menuId='+item.menu_id)"><i class="iconfont" :class="item.icon"></i>{{item.title}}</a>
			</li>
			<li>
				<a @click="outLogin()"><i class="iconfont icon-tuichu"></i>退出登录</a>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapActions,mapMutations,mapState } from "vuex";
import config from "../../assets/js/conf/config";
import headDefault from '../../assets/images/head.png';
export default {
	name: "ucenterMenu",
	props:{
		num:{
			type:String,
			default:'1',
			
		},
		visible:{
			type:Boolean,
			default:false
		}
	},
	watch:{
		visible(val){
			if(val){
				this.ucmenuList = this.ucmenu?this.ucmenu:[]
			}
		}
	},
	data() {
		return {
			headDefault,
			userinfo:"",
			headImg:"",
			uploadAction:"",
			ucmenuList:[],
			curId:0
		};
	},
	mounted(){
		
	},
	computed:{
		...mapState({
			users:(state)=>state.login.userinfo,
			avatar:(state)=>state.login.avatar,
			ucmenu:(state)=>state.login.ucmenu,
		}),
	},
	created(){
		this.uploadAction = config.baseApi + '/business/basinessupload'
		if(this.users){
			this.userinfo = this.users
			this.headImg = this.avatar || headDefault
		}
		this.ucmenuList = this.ucmenu?this.ucmenu:[]
		if(this.$route.query.menuId){
			this.curId = this.$route.query.menuId
		}else{
			this.curId = this.ucmenu[0].menu_id
		}
	},
	methods:{
		...mapActions({
			changeAvatar:"ucenter/changeAvatar",
			getUserinfoData:"login/getUserinfoData"
		}),
		...mapMutations({
			OUT_LOGIN:"login/OUT_LOGIN"
		}),
		handleAvatarSuccess(res, file) {
			// console.log('ccccccc',res,file)
			this.$alert(`<img src="`+res.data.address+`" style="border-radius:50%;width:300px;height:300px;margin:0 auto;display:block;" />`, '上传头像', {
				dangerouslyUseHTMLString: true,
				showCancelButton:true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				this.changeAvatar({data:{
						url:res.data.address,
					},success:(res)=>{
					if(res.code===200){
						console.log('upload success',res)
						this.updateUserinfo()
						this.$message({message: res.msg,type: 'success',});
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
				}})
			}).catch(() => {
				
			});
		},
		updateUserinfo(){
			this.getUserinfoData({data:{},success:(res)=>{
				if(res.code===200){
					console.log('update userinfo success',res)
					this.headImg = res.data.avatar
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		outLogin(){
			this.$message({message: '已退出登录',type: 'success',});
			this.OUT_LOGIN()
			this.$router.replace('./')
		},
	}
}
</script>
<style>
.ucenter_main{align-items: flex-start;}
.right_con{height: 100%;}
</style>
<style scoped>
.left_menu{width: 200px;background: #fff;padding-bottom: 50px;box-sizing: border-box;position: sticky;top: 128px;}
.left_menu .head_wrap{width: 100%;height: 160px;background: #faedee;display: flex;justify-content: center;align-items: center;flex-direction: column;}
.left_menu .head_wrap .head_box{width: 70px;height: 70px;border-radius: 50%;background: #fff;position: relative;cursor: pointer;display: flex;justify-content: center;align-items: center;}
.left_menu .head_wrap .head_box .vip_tag{position: absolute;top: -10px;left: -12px;width: 32px;height: 32px;z-index: 1;}
.left_menu .head_wrap .head_box .icon-touxiang{font-size: 44px;color: #fdaeae;}
.left_menu .head_wrap .head_box .head{border-radius: 50%;position: relative;z-index: 2;width: 70px;height: 70px;}
.left_menu .head_wrap .head_box .icons{width: 20px;height: 20px;border-radius: 50%;background: #f0413e;display: flex;justify-content: center;align-items: center;position: absolute;right: -4px;bottom: 4px;z-index: 2;}
.left_menu .head_wrap .head_box .icons .icon-camera{font-size: 12px;color: #fff;}
.left_menu .head_wrap .txt{line-height: 1;font-weight: bold;margin-top: 14px;font-size: 14px;}
.left_menu .head_wrap .viptxt{line-height: 1;font-size: 12px;color: #666;margin-top: 8px;}
.left_menu .head_wrap .viptxt a{color: #f0413e;margin-left: 6px;}
.left_menu .head_wrap .viptxt a:hover{text-decoration: underline;}
.left_menu .links_list{padding-top: 20px;}
.left_menu .links_list>li{height: 36px;margin-top: 5px;padding: 0 6px;}
.left_menu .links_list>li:first-child{margin-top: 0;}
.left_menu .links_list>li>a{cursor: pointer;width: 100%;height: 100%;display: flex;align-items: center;font-size: 14px;color: #333;border-radius: 4px;box-sizing: border-box;padding: 0 10px;}
.left_menu .links_list>li>a .iconfont{font-size: 16px;margin-right: 12px;}
.left_menu .links_list>li.active>a{color: #f0413e;font-weight: bold;}
.left_menu .links_list>li.active>a .iconfont{font-weight: normal;}
.left_menu .links_list>li>a:active{background: #f2f2f2;}
.left_menu .links_list>li>a:hover{color: var(--red);}
</style>